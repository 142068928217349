import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { useUserStore } from "@/stores/userStore";
import { RouteSecurity } from "@/application/enums/RouteSecurity";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, top: 0, behavior: "smooth" };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  },
});

router.beforeEach(async (to, _from, next) => {
  const userStore = useUserStore();
  await userStore.restoreState();

  // Not logged-in, redirects to login page if page is not Public / StrictlyPublic
  if (!userStore.isConnected && to.name !== "login") {
    if (to.meta?.security !== null) {
      const security = to.meta.security as RouteSecurity;

      if ([RouteSecurity.Public, RouteSecurity.StrictlyPublic].includes(security)) {
        next();
        return;
      }
    }

    next({ name: "login" });
    return;
  }

  // Logged-in, redirects user out of the login page
  if (userStore.isConnected && to.name === "login") {
    next({ name: "my-club" });
    return;
  }

  // Logged-in, trying to access a StriclyPublic route
  if (userStore.isConnected && to.meta?.security === RouteSecurity.StrictlyPublic) {
    next({ name: "my-club" });
    return;
  }

  // Handle the onboarding process
  if (userStore.isConnected && to.name === "my-club" && localStorage.getItem("oval3-onboarding")) {
    next({ name: "onboarding" });
    return;
  }

  next();
});

export default router;
