export const ENVIRONMENTS = {
  PRODUCTION: "PRODUCTION",
  PREPROD: "PREPROD",
  TESTNET: "TESTNET",
  DEVELOPMENT: "DEVELOPMENT",
};

export const environment = import.meta.env.MODE as string;
export const isProd = [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.PREPROD].includes(environment.toUpperCase());
export const build = environment; //import.meta.env.VITE_BUILD_ID ? `v. ${import.meta.env.VITE_BUILD_ID}` : "Local";

export const LOGINLINE_GRAPHQL_URL = import.meta.env.VITE_LOGINLINE_GRAPHQL_URL as string;
export const ERA_GRAPHQL_URL = import.meta.env.VITE_ERA_GRAPHQL_URL as string;

export const MARKETPLACE_BASE_URL = import.meta.env.VITE_MARKETPLACE_BASE_URL as string;

export const TOS_URL = (import.meta.env.VITE_TOS_URL as string) ?? ("#" as string);
export const HELP_URL = (import.meta.env.VITE_HELP_URL as string) ?? ("#" as string);
export const PRIVACY_POLICIES_URL = (import.meta.env.VITE_PRIVACY_POLICIES_URL as string) ?? ("#" as string);
export const GAMERULES_URL = (import.meta.env.VITE_GAMERULES_URL as string) ?? ("#" as string);
export const ONBOARDING_URL = (import.meta.env.VITE_ONBOARDING_URL as string) ?? ("#" as string);
export const BETA_FEEDBACK_URL = (import.meta.env.VITE_BETA_FEEDBACK_URL as string) ?? ("#" as string);

export const REFERRAL_ENABLED = import.meta.env.VITE_ENABLE_GAME_REFERRAL ? import.meta.env.VITE_ENABLE_GAME_REFERRAL === "true" : false;
export const REWARDS_ENABLED = import.meta.env.VITE_ENABLE_GAME_REWARDS ? import.meta.env.VITE_ENABLE_GAME_REWARDS === "true" : false;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string | null;
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN as string | null;
export const GOOGLE_ANALYTICS_TOKEN = import.meta.env.VITE_GOOGLE_ANALYTICS_TOKEN;
export const ANALYTICS_PLATFORM_LABEL = "Game Loaded";
export const PRODUCT_FRUITS_WORKSPACE_CODE = import.meta.env.VITE_PRODUCT_FRUITS_WORKSPACE_CODE as string;
